<template>
    <div class="projectDetail">
        <div class="container project-blocks" v-for="block in projectDetailEntry" v-bind:key="block">
            <div class="row project-block project-text" v-if="block.type=='text'">
                <div class="col-12 text" v-html="block.text"></div>
            </div>
            <template v-if="block.type=='table'">
                <div class="row project-block padding-project" v-for="item in block.table" v-bind:key="item">
                    <div class="col-12 col-md-6">
                        <p>{{ item.textLeft }}</p>
                    </div>
                    <div class="col-12 col-md-6">
                        <p><span class="d-inline d-md-none">&#8226;</span> {{ item.textRight }}</p>
                    </div>
                    <div class="col-12">
                        <hr class="border-b">
                    </div>
                </div>
            </template>
            <template v-if="block.type=='tableAccordion'">
                <div class="row padding-project justify-content-between tableAccordion" @click="accordion(block)">
                    <div class="col-auto">
                        <h2>{{ block.title }}</h2>
                    </div>
                    <div class="col-auto">
                        <a class="svg-link style-none d-block d-md-inline" v-bind:class="{ 'is-open': block['is-open'] }">
                            <svg class="d-none d-md-inline accordion-svg" v-bind:class="{ 'is-open': block['is-open'] }" width="17" height="10" xmlns="http://www.w3.org/2000/svg"><path d="M8.468 9.175l.017.017.707-.707-.017-.017 7.76-7.76L16.228 0l-7.76 7.76L.708 0 0 .707l7.76 7.76-.017.018.707.707z" fill="#000" fill-rule="evenodd"/></svg>
                            <svg class="d-inline d-md-none accordion-svg" v-bind:class="{ 'is-open': block['is-open'] }"  width="14" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M13 1L7 7 1 1" stroke="#000" fill="none" fill-rule="evenodd"/></svg>
                        </a>
                    </div>
                </div>
                <div class="accordion-body" v-bind:class="{ 'is-open': block['is-open'] }">
                    <div class="row project-block padding-project" v-for="item in block.tableAccordion12" v-bind:key="item">
                        <div class="col-12 col-md-6">
                            <p class="accordion-indent">{{ item.textLeft }}</p>
                        </div>
                        <div class="col-12 col-md-6">
                            <p><span class="d-inline d-md-none">&#8226;</span> {{ item.textRight }}</p>
                        </div>
                    </div>
                </div>
                <div class="row padding-project">
                    <div class="col-12">
                        <hr class="border-b">
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        projectDetailEntry: Object
    },
    methods: {
        accordion(block){
            block['is-open'] = block['is-open'] ? false : true;
        },
    }
}
</script>
