<template>
    <div class="project transition-wrapper"  >
      <div ref="project">
          <svg class="mobile-arrow-left" v-if="this.$store.state.projects.mobileArrows" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 1L1 5L5 9" stroke="#333333" stroke-linecap="round"/></svg>
          <svg class="mobile-arrow-right" v-if="this.$store.state.projects.mobileArrows" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5 5L1 9" stroke="#333333" stroke-linecap="round"/></svg>
          <svg class="mobile-arrow-down" v-if="this.$store.state.projects.mobileArrows" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 1L5 5L0.999998 1" stroke="#333333" stroke-linecap="round"/></svg>

          <!-- <button class="button-prev" type="button" name="button" v-if="this.$store.getters['projects/getPrevProjectSlug'](slug)" @click="showPrevProject" ></button>
          <button class="button-next"  type="button" name="button" v-if="this.$store.getters['projects/getNextProjectSlug'](slug)" @click="showNextProject"></button> -->

          <button class="button-prev" type="button" name="button"  @click="showPrevProject" ></button>
          <button class="button-next"  type="button" name="button" @click="showNextProject"></button>
          <div class="container-fluid project-wrapper" ref="projectWrapper" v-if="project">
              <div class="row h-100 position-relative">

                      <div ref="slider" class="keen-slider">
                        <div class="keen-slider__slide slide number-slide1" v-for="image in project.projectGallery" v-bind:key="image">
                            <div class="w-100 h-auto d-flex justify-content-center">
                                <!-- <img :src="image.src" :alt="image.title" class="img-projects" :class="image.orientation"/> -->
                                <picture class="img-projects" :class="image.orientation">
                                    <source media="(max-width: 720px)" :srcset="image.src.transformMobile">
                                    <img :src="image.src.transformDesktop">
                                </picture>
                                <aside class="loader animated"></aside>
                            </div>
                        </div>
                      </div>

                      <div
                          @click="slider.prev()"
                          :class="{
                              arrow: true,
                              'arrow--left': true,
                              /* 'arrow--disabled': current === 0, */
                              }"
                        >
                        </div>
                      <div
                            v-if="slider"
                            @click="slider.next()"
                            :class="{
                                arrow: true,
                                'arrow--right': true,
                                /* 'arrow--disabled': current === slider.details().size - 1, */
                                mobileArrows: true,
                            }"
                          >
                      </div>

                    <h1 class="project-title text-center">{{ project.title }}</h1>
                    <!-- <h1 class="project-title text-center">{{ $store.getters['projects/getPositionProjectBySlug'](slug)+1 }}. {{ project.title }}</h1> -->
                    </div>
                    <div class="row project-footer">
                        <div class="col-6">
                        <p>{{ $store.getters['projects/getPositionProjectBySlug'](slug)+1 }}/{{ $store.getters['projects/get'].length }}</p>
                    </div>
                    <div class="col-6 d-flex flex-row-reverse">
                        <button
                          type="button"
                          class="btn"
                          @click="showModal"
                        >
                            <p>Projektinfo</p>
                        </button>
                    </div>
              </div>
          </div>
      </div>
      <Modal
          v-show="isModalVisible"
          @close="closeModal"
          >
          <template v-slot:header v-if="project">
              {{ project.title }}
          </template>
          <template v-slot:body v-if="project">
              <project-detail :projectDetailEntry="project.blocks"/>
          </template>
          <template v-slot:footer></template>
        </Modal>
    </div>
</template>

<script>
    import "keen-slider/keen-slider.min.css";
    import KeenSlider from "keen-slider";
    import Modal from '../components/Modal.vue';
    import ProjectDetail from '../components/ProjectDetail.vue';

    export default {
        name: 'Project',
        components: {
          Modal,
          ProjectDetail,
        },
        props: {
            slug: String,
            isStudioModalVisible: Boolean,
        },
        data () {
            return {
                loaded: false,
                time: null,
                isMoving: false,
                isModalVisible: false,
                current: 0,
                slider: null
            };
        },
        computed: {
            project: function(){
                return this.$store.getters['projects/getProjectBySlug'](this.slug)
            }
        },
        mounted(){
            console.log("mounted");
            var _this = this;
            window.addEventListener('wheel', this.handleScroll);
            window.addEventListener('keydown', this.handleKeyDown);
            //console.log(this.$store.getters['projects/getProjectBySlug'](this.slug))
            //this.project = this.$store.getters['projects/getProjectBySlug'](this.slug);

            var time = setInterval(function(){
                if(_this.project){
                    clearTimeout(time);
                    _this.initGallery();
                }
            }, 100);

            let touchstartY = 0;
            let touchendY = 0;

            const gestureZone = this.$refs.project;
            gestureZone.addEventListener('touchstart', function(event) {
                touchstartY = event.changedTouches[0].screenY;
            }, false);

            gestureZone.addEventListener('touchend', function(event) {
                touchendY = event.changedTouches[0].screenY;
                handleGesture(_this);
            }, false);

            function handleGesture(_this) {
                _this.$store.state.projects.mobileArrows = false;
                if (touchendY <= touchstartY && touchstartY - touchendY >= 105 ){
                    console.log('Swiped direction up' + touchstartY - touchendY );
                    if(_this.$store.getters['projects/getNextProjectSlug'](_this.slug)){
                        _this.showNextProject();
                    }
                }
                if (touchendY >= touchstartY && touchendY - touchstartY >= 105 ) {
                    console.log('Swiped direction down' + touchendY - touchstartY);
                    if(_this.$store.getters['projects/getPrevProjectSlug'](_this.slug)){
                        _this.showPrevProject();
                    }
                }
            }
        },
        unmounted () {
            console.log("unmounted");
            window.removeEventListener('wheel', this.handleScroll);
            window.removeEventListener('keydown', this.handleKeyDown);
            // if (this.slider) this.slider.destroy();
        },

        methods: {
            hideArrows(){
                // if (this.current > 0) {
                // }
                this.mobileArrows = false;
            },
            initGallery(){
                this.slider = new KeenSlider(this.$refs.slider, {
                  initial: this.current,
                  loop: true,
                  created: () => {

                  },
                  slideChanged: (s) => {
                    this.current = s.details().relativeSlide
                  },
                })
            },
            showPrevProject(){
                const nextProjectSlug = this.$store.getters['projects/getPrevProjectSlug'](this.slug);
                this.$router.push({ name: 'Project', params: { slug: nextProjectSlug, transformationDirection: 'down' } });
                this.$store.state.projects.mobileArrows = false;
            },
            showNextProject(){
                const prevProjectSlug = this.$store.getters['projects/getNextProjectSlug'](this.slug);
                this.$router.push({ name: 'Project', params: { slug: prevProjectSlug, transformationDirection: 'up' } });
                this.$store.state.projects.mobileArrows = false;
            },
            handleKeyDown(e){
                if (e.keyCode == '38') {
                    if(this.$store.getters['projects/getPrevProjectSlug'](this.slug)){
                        this.showPrevProject();
                    }
                }
                else if (e.keyCode == '40') {
                    if(this.$store.getters['projects/getNextProjectSlug'](this.slug)){
                        this.showNextProject();
                    }
                }
                else if (e.keyCode == '37') {
                   this.slider.prev()
                }
                else if (e.keyCode == '39') {
                  this.slider.next()
                }
            },
            handleScroll (event) {
                if(!this.$store.state.projects.isMoving && !this.isModalVisible &&!this.isStudioModalVisible && this.$store.state.projects.data.length){
                    this.$store.state.projects.isMoving = true;
                    var _this = this;
                    window.clearTimeout( this.time );
                    this.time = setTimeout(function(){
                        _this.$store.state.projects.isMoving = false;
                    }, 2000);

                    if(event.deltaY > 0 && this.$store.getters['projects/getNextProjectSlug'](this.slug)){
                        this.showNextProject();
                    }else if(event.deltaY < 0 && this.$store.getters['projects/getPrevProjectSlug'](this.slug)){
                        this.showPrevProject();
                    }else {
                        window.clearTimeout( this.time );
                        this.$store.state.projects.isMoving = false;
                    }
                }
            },
            showModal() {
                this.isModalVisible = true;
                window.$( "#app" ).addClass( "modalIsOpen" );
            },
            closeModal() {
                this.isModalVisible = false;
                window.$( "#app" ).removeClass( "modalIsOpen" );
            },
            keyArrowPressed() {
            }
        }
    }

</script>
