<template>
  <div class="studio">
      <div class="container-fluid site-title">
          <div class="row">
              <!-- <div class="col-12">
                  <h1>{{ this.$store.getters['studio/get'].title }}</h1>
              </div> -->
          </div>
      </div>
      <div class="container studio-blocks" v-for="item in this.$store.getters['studio/get'].blocks" v-bind:key="item">
          <div class="row studio-block text padding-studio" v-if="item.type=='text'">
              <div class="col-12" v-html="item.text" v-bind:class="{ isAccordion: item.accordion, 'is-open': item['is-open'] }"></div>
              <a class="show-more" @click="accordion(item)" v-bind:class="{ 'is-open': item['is-open'] }"><p>Mehr anzeigen</p></a>
              <a class="show-less" @click="accordion(item)" v-bind:class="{ 'is-open': item['is-open'] }"><p>Weniger anzeigen</p></a>
          </div>
          <div class="row studio-block" v-if="item.type=='image'">
              <div class="col-12">
                  <img :src="item.image" alt="" class="img-fluid">
              </div>
          </div>
          <div class="row studio-block heading padding-studio" v-if="item.type=='heading'">
              <div class="col-12">
                  <p>{{ item.title }}</p>
              </div>
          </div>
          <div class="row studio-block padding-studio" v-if="item.type=='textTwoColumns'">
              <div class="col-6"  v-html="item.textLeft"></div>
              <div class="col-6"  v-html="item.textRight"></div>
          </div>
      </div>
  </div>
</template>

<script>

export default {
    name: 'Studio',
    props: {},
    data () {
        return {
            loaded: false
        };
    },
    computed: {
        // a computed getter
        content() {
            let page = this.$store.getters.getPageBySlug('studio');
            if (page)
                return page.blocks;
            else
                return [];
        }
    },
    mounted(){
        this.loaded = true;
    },
    methods: {
        accordion(item){
            item['is-open'] = item['is-open'] ? false : true;
        },
    }
}

</script>
